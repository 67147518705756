<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top">
            <img src="../../assets/img/logo.png" class="rounded mx-auto d-block nav-img" >
        </b-navbar>
        <b-container class="content-section" >
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <div class="row status-info">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12" v-if="cartItems.length > 0">
                            <table class="table table-cart" >
                                <tr v-for="(item, index) in cartItems">
                                    <td>{{item.itemName}}</td>
                                    <td style="width: 100px;">
                                        <input v-model="item.qty" class="form-control input-qty" type="number"
                                            @blur="updateItem(item)">
                                    </td>
                                    <td>
                                        <button class="btn-delete" @click="removeItem(index)"><i class="fas fa-trash-alt"></i></button>
                                    </td>
                                </tr>
                            </table>
                            <textarea class="form-control mb-2"
                                placeholder="ပို့ဆောင်ရမည့် လိပ်စာ" v-model="address">{{address}}</textarea>
                            <small class="text-danger">{{message}}</small>
                            <button class="btn btn-bg mt-2" style="width: 100%;" @click="orderingMerchant()">နီးစပ်ရာဆိုင်မှ မှာမည်</button> <br> <br>

                            <!-- <button class="btn btn-bg mt-2" style="width: 100%;" @click="orderingFactory()">စက်ရုံတိုက်ရိုက် မှာမည်</button> -->
                        </div>
                        <div class="col-md-12 mt-5" v-else>
                            <p class="text-center">Empty Cart</p>
                        </div>
                    </div>
                </div>
            </div>
        </b-container>
    </div>
</template>

<script lang="js">
    import {
        mapMutations,
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'item',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                message: "",
                distributorList: [],
                lat: "",
                long: "",
                cartItems: [],
                address: "",
                user: []
            }
        },
        computed: {},
        methods: {
            ...mapMutations({
                add: 'increment'
            }),
            ...mapActions({
                distributorListAction: 'distributorListAction',
                orderingAction: 'orderingAction',
                orderingFactoryAction: 'orderingFactoryAction',
            }),
            async getDistributorList() {
                this.isLoading = true;
                await this.distributorListAction().then(res => {
                    if (res.status == "success") {
                        this.distributorList = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            getItem() {
                const item = JSON.parse(window.localStorage.getItem('cart'))
                const user = JSON.parse(window.localStorage.getItem('user'))
                this.user = {
                    "address": user.address,
                    "firebase_token": user.firebase_token,
                    "lat": user.lat,
                    "long": user.long,
                    "name":  user.name,
                    "password": user.password,
                    "phone": user.phone,
                    "role": user.role,
                    "token": user.token
                }
                this.address = user.address
                this.lat = user.lat
                this.long = user.long
                // console.log(item)
                if(item){
                    this.cartItems = item
                }else{
                    this.cartItems = []
                }
            },
            updateItem(item) {
                const record = this.cartItems.find(p => p.id === item.id)
                // record.qty++
                window.localStorage.setItem('cart', JSON.stringify(this.cartItems))
            },
            removeItem(index) {
                this.cartItems.splice(index, 1)
                window.localStorage.setItem('cart', JSON.stringify(this.cartItems))
                this.$store.commit('increment')
            },
            calcCrow(lat1, lon1, lat2, lon2) {
                var R = 6371; // km
                var dLat = this.toRad(lat2 - lat1);
                var dLon = this.toRad(lon2 - lon1);
                var lat1 = this.toRad(lat1);
                var lat2 = this.toRad(lat2);

                var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
                    Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
                var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
                var d = R * c;
                return d;
            },
            toRad(Value) {
                return Value * Math.PI / 180;
            },
            calcNearBy() {
                let vm = this
                let arr = []
                let arrNear = []
                this.distributorList.forEach(function (key, value) {
                    let lat2 = key.lat
                    let lon2 = key.long
                    let lat1 = vm.lat
                    let lon1 = vm.long
                    var near = vm.calcCrow(lat1, lon1, lat2, lon2).toFixed(1)
                    arrNear.push({
                        "id": key.id,
                        "name": key.name,
                        "distance": near
                    })
                    arr.push(near)
                })
                // console.log(arr)
                // console.log(arrNear)
                var min = Math.min.apply(null, arr)
                // console.log(min)
                let index = arrNear.findIndex(x => x.distance == min);
                return arrNear[index]
            },
            async orderingMerchant() {
                let arr = {
                    "address": this.address,
                    "firebase_token": this.user.firebase_token,
                    "lat": this.user.lat,
                    "long": this.user.long,
                    "name":  this.user.name,
                    "password": this.user.password,
                    "phone": this.user.phone,
                    "role": this.user.role,
                    "token": this.user.token
                }
                
                this.isLoading = true
                if(this.address == ''){
                    this.message = "ပို့ဆောင်ရမည့် လိပ်စာ ဖြည့်ပေးပါ"
                    this.isLoading = false
                    return
                }
                let shop = this.calcNearBy()
                let option = {
                    item: this.cartItems,
                    distributor: shop,
                    address: this.address
                }
                await this.orderingAction({
                    ...option
                }).then(res => {
                    this.isLoading = false
                    window.localStorage.setItem('user', JSON.stringify(arr))
                    window.localStorage.setItem('cart', null)
                    this.$router.replace('/order-list')
                }).catch(err => this.isLoading = true)
            },
            async orderingFactory() {
                let arr = {
                    "address": this.address,
                    "firebase_token": this.user.firebase_token,
                    "lat": this.user.lat,
                    "long": this.user.long,
                    "name":  this.user.name,
                    "password": this.user.password,
                    "phone": this.user.phone,
                    "role": this.user.role,
                    "token": this.user.token
                }
                
                this.isLoading = true
                if(this.address == ''){
                    this.message = "ပို့ဆောင်ရမည့် လိပ်စာ ဖြည့်ပေးပါ"
                    this.isLoading = false
                    return
                }
                let option = {
                    item: this.cartItems,
                    address: this.address
                }
                await this.orderingFactoryAction({
                    ...option
                }).then(res => {
                    this.isLoading = false
                    window.localStorage.setItem('user', JSON.stringify(arr))
                    window.localStorage.setItem('cart', null)
                    this.$router.replace('/order-list')
                }).catch(err => this.isLoading = true)
            }
        },
        async mounted() {
            this.getItem()
            this.getDistributorList()
        }
    }
</script>

<style scoped lang="scss">
    .action {
        border: none;
        background: none;
        font-size: 24px;
    }

    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 70px;
        right: 3%;
        background-color: #0C9;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .float-filter {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 140px;
        right: 2%;
        background-color: #318be3;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .cart {
        z-index: 999;
        position: absolute;
        color: #dc3545;
        bottom: 55%;
        right: 16%;
        font-size: 18px;
        font-weight: bolder;
    }

    .close-cart {
        z-index: 999;
        position: absolute;
        right: 5%;
        top: -2%;
        font-size: 24px;
    }
</style>